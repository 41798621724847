<template>
  <div>
    <b-card bg-variant="light"
            header="Proposals"
            header-bg-variant="dark"
            header-text-variant="white">
      <b-tabs v-model="selected_card"
              card>

        <b-tab lazy
               title="Proposals list">
          <proposals_list :fields="fields"/>
        </b-tab>

      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import proposals_list from '@/components/operational/proposals/proposals_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'proposals_dashboard',
    components: { proposals_list },
    data() {
      return {
        selected_card: 1,
        fields: [
          ordinalNumber,
          { key: 'document_specification.document_id', label: 'ID', sortable: true },
          { key: 'title', label: 'Title' },
          { key: 'author', label: 'Author' },
          { key: 'states.name', label: 'State' },
          { key: 'general.end_station', label: 'End Station' },
          { key: 'document_specification.subtypes', label: 'Subtypes' },
          { key: 'actions', label: 'Actions' },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
